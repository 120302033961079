var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-group-info" },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-back",
          attrs: { slot: "left-one" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          },
          slot: "left-one"
        })
      ]),
      _c(
        "div",
        { staticClass: "chat-group-info__content" },
        _vm._l(Object.keys(_vm.types), function(item, index) {
          return _c(
            "van-cell-group",
            { key: index },
            [
              _c(
                "van-cell",
                {
                  attrs: {
                    title: _vm.types[item].name,
                    label: _vm.types[item].desc
                  },
                  on: {
                    click: function($event) {
                      _vm.select
                        ? _vm.handlerSelect(item, _vm.types[item].name)
                        : ""
                    }
                  }
                },
                [
                  _vm.curType === item
                    ? _c("van-icon", {
                        attrs: {
                          slot: "right-icon",
                          name: "checked",
                          size: "20px",
                          color: "#33cc66"
                        },
                        slot: "right-icon"
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }