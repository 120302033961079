<template>
  <div class="chat-group-info">
    <Header>
      <i slot="left-one" class="me-icon-back" @click="$router.go(-1)"></i>
    </Header>
    <div class="chat-group-info__content">
      <van-cell-group v-for="(item, index) in Object.keys(types)" :key="index">
        <van-cell :title="types[item].name" :label="types[item].desc" @click="select ? handlerSelect(item,types[item].name) : ''">
          <van-icon
            name="checked"
            slot="right-icon"
            v-if="curType === item"
            size="20px"
            color="#33cc66"
          />
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>
<script>
import Header from "@/components/business/h5/header";
export default {
  name: "group-info",
  components: {
    Header
  },
  data() {
    return {
      curType: "",
      select:false,
      types:{},
      type:''
    };
  },
  created() {
    
    this.select = this.$route.query.select ? true : false
    this.type = this.$route.query.type || ''
    if(this.select)
      localStorage.removeItem('groupType')
    this.curType = this.type || localStorage.getItem("groupType") || '1';
    const catchGroupStr = localStorage.getItem("createGroupType")
    const createGroupCatch = catchGroupStr ? JSON.parse(catchGroupStr) : {}
    if(this.select && !this.curType)
      this.curType = createGroupCatch.type
    if(this.select){
      
      Object.keys(this.$t('groupType')).map(key=>{
        if(key !== '3') this.types[key] = this.$t('groupType')[key]
      })
    }else {
      this.types = this.$t('groupType')
    }
  },
  methods:{
    handlerSelect(item,name){
      this.curType = item      
      localStorage.setItem('createGroupType',JSON.stringify({type:item == 4 ? '3' : item ,name}))
      setTimeout(()=>{
        this.$router.go(-1);
      },100)
    }
  }
};
</script>
<style lang="stylus">
.chat-group-info {
  .chat-group-info__content {
    padding-top: 54px;
  }
}
</style>